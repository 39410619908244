exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-branze-js": () => import("./../../../src/pages/branze.js" /* webpackChunkName: "component---src-pages-branze-js" */),
  "component---src-pages-depoty-js": () => import("./../../../src/pages/depoty.js" /* webpackChunkName: "component---src-pages-depoty-js" */),
  "component---src-pages-firma-js": () => import("./../../../src/pages/firma.js" /* webpackChunkName: "component---src-pages-firma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-koszyk-jsx": () => import("./../../../src/pages/koszyk.jsx" /* webpackChunkName: "component---src-pages-koszyk-jsx" */),
  "component---src-pages-miejscowosci-js": () => import("./../../../src/pages/miejscowosci.js" /* webpackChunkName: "component---src-pages-miejscowosci-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-polityka-firmy-js": () => import("./../../../src/pages/polityka-firmy.js" /* webpackChunkName: "component---src-pages-polityka-firmy-js" */),
  "component---src-pages-poznaj-nas-js": () => import("./../../../src/pages/poznaj-nas.js" /* webpackChunkName: "component---src-pages-poznaj-nas-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */),
  "component---src-pages-wycena-js": () => import("./../../../src/pages/wycena.js" /* webpackChunkName: "component---src-pages-wycena-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-depot-js": () => import("./../../../src/templates/depot.js" /* webpackChunkName: "component---src-templates-depot-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

